import React from 'react';
import RegistrationPic from "./registration.jpg";

function Registration() {
    return (
        <div className="">
            {/* Image Section */}
            <div className="registration">
                <img
                    className="w-full h-48 md:h-64 lg:h-96 object-cover object-center"
                    src={RegistrationPic}
                    alt="Nature Scene"
                />
            </div>

            {/* Becoming a Member Section */}
            <div className="flex flex-col justify-center items-center my-8 md:my-14 mx-auto max-w-7xl px-4 lg:px-8">
                <h1 className="font-black leading-none text-gray-800 uppercase dark:text-white text-center text-lg md:text-xl lg:text-2xl">
                    Fees Payable to Land Surveyors Board
                </h1>

                <div className="font-black leading-4 text-gray-800 uppercase dark:text-white text-center">
                    <p className='mt-1.5'>LSB SERVICES</p>
                    <p className='font-medium'>PAYMENTS TO THE BOARD THROUGH E-CITIZEN via MPESA PAYBILL</p>
                </div>

                {/* Table Section */}
                <div className="w-full overflow-x-auto mt-3">
                    <table className="min-w-full table-auto border-collapse border border-gray-300">
                        <thead className="bg-gray-600 text-white">
                            <tr>
                                <th className="p-2">Service</th>
                                <th className="p-2">Business Number</th>
                                <th className="p-2">Account Number</th>
                                <th className="p-2">Service Code</th>
                                <th className="p-2">Amount (Ksh.)</th>
                                <th className="p-2">Notes</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr className="border-b border-gray-300">
                                <td className="p-2">Annual Renewal of Practicing License – Licensed Surveyor</td>
                                <td className="p-2 text-center">222222</td>
                                <td className="p-2 text-center">ARPL-<span className="text-red-500 font-bold">XXX</span></td>
                                <td className="p-2"><span className="text-red-500 font-bold">XXX</span> denotes License Number</td>
                                <td className="p-2 text-center">5,050.00</td>
                                <td className="p-2 text-center px-4 bg-gray-100" rowSpan="7">All transactions MUST include the KSH 50 E-Citizen Convenience fee</td>
                            </tr>

                            <tr className="border-b border-gray-300">
                                <td className="p-2">Annual Renewal Fees – Approved Assistant</td>
                                <td className="p-2 text-center">"</td>
                                <td className="p-2 text-center">ANFA-<span className="text-red-500 font-bold">XXX</span></td>
                                <td className="p-2"><span className="text-red-500 font-bold">XXX</span> denotes License Number</td>
                                <td className="p-2 text-center">1,050.00</td>
                            </tr>

                            <tr className="border-b border-gray-300">
                                <td className="p-2">Application for Kenya Survey License Registration</td>
                                <td className="p-2 text-center">"</td>
                                <td className="p-2 text-center">AKSL-<span className="text-red-500 font-bold">YYY</span></td>
                                <td className="p-2"><span className="text-red-500 font-bold">YYY</span> denotes Name of Applicant</td>
                                <td className="p-2 text-center">15,050.00</td>
                            </tr>

                            <tr className="border-b border-gray-300">
                                <td className="p-2">Application for Registration of Approved Assistant</td>
                                <td className="p-2 text-center">"</td>
                                <td className="p-2 text-center">ARAA-<span className="text-red-500 font-bold">XXX</span></td>
                                <td className="p-2"><span className="text-red-500 font-bold">XXX</span> denotes License Number</td>
                                <td className="p-2 text-center">2,550.00</td>
                            </tr>

                            <tr className="border-b border-gray-300">
                                <td className="p-2">Application for Land Law Examination</td>
                                <td className="p-2 text-center">"</td>
                                <td className="p-2 text-center">ALLE-<span className="text-red-500 font-bold">YYY</span></td>
                                <td className="p-2"><span className="text-red-500 font-bold">XXX</span> denotes Name of Applicant</td>
                                <td className="p-2 text-center">5,050.00</td>
                            </tr>

                            <tr className="border-b border-gray-300">
                                <td className="p-2">Issue of Identity Cards</td>
                                <td className="p-2 text-center">"</td>
                                <td className="p-2 text-center">ISDC-<span className="text-red-500 font-bold">XXX</span></td>
                                <td className="p-2"><span className="text-red-500 font-bold">XXX</span> denotes License Number</td>
                                <td className="p-2 text-center">3,050.00</td>
                            </tr>

                            <tr className="border-b border-gray-300">
                                <td className="p-2">Issue of Bound Land Law Papers</td>
                                <td className="p-2 text-center">"</td>
                                <td className="p-2 text-center">BLLP-<span className="text-red-500 font-bold">YYY</span></td>
                                <td className="p-2"><span className="text-red-500 font-bold">XXX</span> denotes Name of Applicant</td>
                                <td className="p-2 text-center">5,050.00</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default Registration;