import React from 'react'

const AssistantsTab = () => {
    return (
        <div>
            <h4 className='font-bold underline underline-offset-2'>Your Assistants</h4>

            <table className='w-full'>
                <thead className="uppercase">
                    <tr>
                        <th scope="col" className="py-3 px-3 bg-black text-white">
                            APPROVED ASST.
                        </th>
                        <th scope="col" className="py-3 px-3 bg-black text-white">
                            assistant id.
                        </th>
                        <th scope="col" className="py-3 px-3 bg-black text-white">
                            email
                        </th>
                        <th scope="col" className="py-3 px-3 bg-black text-white">
                            phone no.
                        </th>
                        <th scope="col" className="py-3 px-3 bg-black text-white">
                            status
                        </th>
                    </tr>
                </thead>

                <tbody></tbody>
            </table>
        </div>
    )
}

export default AssistantsTab;