import React, { useState, useEffect } from "react";

const backendUrl = "https://portal.lsb.go.ke/account/licensed/";

function CurrentRegister() {
  const [surveyors, setSurveyors] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage] = useState(20); // Number of items to fetch per page

  // Fetch data from the backend with limit, offset, and search term
  const fetchData = async (page, searchTerm) => {
    setLoading(true);
    const offset = (page - 1) * itemsPerPage; // Calculate offset based on the current page
    try {
      const response = await fetch(`${backendUrl}?limit=${itemsPerPage}&offset=${offset}&search=${searchTerm}`);
      const data = await response.json();
      if (data.results.length === 0) {
        setSurveyors([]); // Clear surveyors if user does not exist
        setError("User does not exist");
      } else {
        setSurveyors(data.results);
        setTotalPages(Math.ceil(data.count / itemsPerPage)); // Update total pages based on the total count
        setError(null); // Clear error if data is found
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("Does not Exist");
      setLoading(false);
    }
  };

  // Fetch data when component mounts or when the currentPage or searchTerm changes
  useEffect(() => {
    fetchData(currentPage, searchTerm);
  }, [currentPage, searchTerm]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const renderTableHeaders = () => (
    <thead className="uppercase">
      <tr>
        <th scope="col" className="py-3 px-3 bg-black text-white">
          SNo.
        </th>
        <th scope="col" className="py-3 px-3 bg-black text-white">
          NAME
        </th>
        <th scope="col" className="py-3 px-3 bg-black text-white">
          LICENCE
        </th>
        <th scope="col" className="py-3 px-3 bg-black text-white">
          ORGANIZATION
        </th>
        <th scope="col" className="py-3 px-3 bg-black text-white">
          ADDRESS
        </th>
        <th scope="col" className="py-3 px-3 bg-black text-white">
          STATUS
        </th>
      </tr>
    </thead>
  );

  const renderTableRows = (data) => {
    if (data.length === 0) {
      return <tr><td colSpan="6" className="text-center">User does not exist</td></tr>;
    }
    return (
      <tbody>
        {data.map((surveyor, index) => (
          <tr
            key={index}
            className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
          >
            <td className="py-2 px-3">{surveyor.licensed_id}</td>
            <td className="px-3">{surveyor.name}</td>
            <td className="px-3">{surveyor.license_no}</td>
            <td className="px-3">{surveyor.firm}</td>
            <td className="px-3">{surveyor.postal_address}</td>
            <td className="px-3">{surveyor.status}</td>
          </tr>
        ))}
      </tbody>
    );
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return (
      <div>
        <div className="relative overflow-x-auto">
          <label
            htmlFor="licensedSurveyor"
            className="relative block overflow-hidden border-b border-gray-200 bg-transparent pt-3 focus-within:border-blue-600"
          >
            <input
              type="text"
              placeholder="Search by name..."
              className="peer h-12 w-full border-none bg-gray-200 px-4 placeholder-transparent focus:border-transparent focus:outline-none focus:ring-0 rounded-t-lg fw-bold"
              value={searchTerm}
              onChange={handleSearchChange}
              autoFocus // This line ensures the input is active to allow the whole input instead of only just a single letter
            />

            <span
              className="absolute start-0 top-2 -translate-y-1/2 text-xs text-gray-800 transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-sm peer-focus:top-2 peer-focus:text-xs px-4"
            >
              Search by name...
            </span>
          </label>
        </div>
        <div>Error: {error}</div>
      </div>
    );
  }

  return (
    <div>
      <div className="relative overflow-x-auto">
        <label
          htmlFor="licensedSurveyor"
          className="relative block overflow-hidden border-b border-gray-200 bg-transparent pt-3 focus-within:border-blue-600"
        >
          <input
            type="text"
            placeholder=""
            className="peer h-12 w-full border-none bg-gray-200 px-4 placeholder-transparent focus:border-transparent focus:outline-none focus:ring-0 rounded-t-lg fw-bold"
            value={searchTerm}
            onChange={handleSearchChange}
            autoFocus // This line ensures the input is active to allow the whole input instead of only just a single letter
          />

          <span
            className="absolute start-0 top-2 -translate-y-1/2 text-xs text-gray-800 transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-sm peer-focus:top-2 peer-focus:text-xs px-4"
          >
            Search by name...
          </span>
        </label>
      </div>

      <div className="overflow-x-auto mt-2">
        <table className="w-full text-sm text-left rtl:text-right table table-striped">
          {renderTableHeaders()}
          {renderTableRows(surveyors)}
        </table>
      </div>

      <span style={{ fontSize: 'calc(.7em + .3vw)' }} className="italic font-medium">Page {currentPage} of {totalPages}</span>

      <div className="flex justify-start items-center gap-2 pt-2">
        <button className="button mt-4" onClick={handlePreviousPage}
          disabled={currentPage === 1}>
          <span className="button_lg">
            <span className="button_sl"></span>
            <span className="button_text" style={{ fontSize: 'calc(.5em + .3vw)' }}>previous</span>
          </span>
        </button>

        <button className="button mt-4" onClick={handleNextPage}
          disabled={currentPage === totalPages}>
          <span className="button_lg">
            <span className="button_sl"></span>
            <span className="button_text" style={{ fontSize: 'calc(.5em + .3vw)' }}>next</span>
          </span>
        </button>
      </div>
    </div>
  );
}

export default CurrentRegister;