import React, { useState } from 'react';

function Tab({ tabs }) {
  const [activeTab, setActiveTab] = useState(tabs[0].id);

  return (
    <div className="w-90vw"> {/* Changed max-w-lg to w-80vw */}
      <div className="flex pt-3">
        {tabs.map(tab => (
          <button
            key={tab.id}
            onClick={() => setActiveTab(tab.id)}
            className={`${
              activeTab === tab.id
                ? 'border-blue-500 text-blue-600'
                : 'border-transparent text-gray-500 hover:text-gray-700'
            } flex-1 inline-flex justify-center py-2 px-6 border-b-2 uppercase`}
          >
            <h3 className='font-bold'>{tab.label}</h3>
          </button>
        ))}
      </div>

      <div className="p-7" style={{ width: '95vw' }}> {/* Set a fixed width */}
        {tabs.map(tab => (
          <div
            key={tab.id}
            className={`${activeTab !== tab.id && 'hidden'}`}
          >
            {tab.content}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Tab;
