import React from 'react';

const NotFound = () => {
  return (
    <div className="h-screen flex flex-col justify-center items-center text-[#944C04]">
        <p className="text-3xl lg:text-7xl font-bold">!404</p>
        <p className="italic font-bold">Ooops........seems like you have wondered away from our content.</p>
    </div>
  )
}

export default NotFound;