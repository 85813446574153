import React, { useState, useEffect } from "react";
import "./complaints.css";
import ComplaintPic from "./complaint.webp";
import List from "../list";

function Complaints() {
    const [names, setNames] = useState([]);
    const [selectedName, setSelectedName] = useState("");
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
    const [uploadedDocuments, setUploadedDocuments] = useState([]);
    const [uploadError, setUploadError] = useState("");

    // Function to handle file selection
    const handleFileSelect = (e) => {
        const files = e.target.files;
        const allowedTypes = ['application/pdf', 'image/jpeg', 'image/png', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
        const selectedFiles = Array.from(files);
        const invalidFiles = selectedFiles.filter(file => !allowedTypes.includes(file.type));

        if (invalidFiles.length > 0) {
            setUploadError('Invalid file type. Please upload a DOCX, PNG, JPG, or PDF file.');
            e.target.value = ''; // Clear the file input
        } else {
            setUploadedDocuments([...uploadedDocuments, ...selectedFiles]);
            setUploadError('');
        }
    };

    // Fetch licensed surveyors' names from the API
    useEffect(() => {
        fetch("https://portal.lsb.go.ke/account/licensed/")
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                return response.json();
            })
            .then((data) => {
                // Extract only the names from the response
                const extractedNames = data.map((item) => item.name);
                setNames(extractedNames);
            })
            .catch((error) => console.error("Error fetching data:", error));
    }, []);

    // Disable submit button if no name is selected
    useEffect(() => {
        setIsSubmitDisabled(selectedName === "");
    }, [selectedName]);

    const handleNameChange = (event) => {
        setSelectedName(event.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        // Handle form submission
        console.log("Form submitted:", { selectedName, uploadedDocuments });
    };

    const removeFile = (index) => {
        const updatedFiles = [...uploadedDocuments];
        updatedFiles.splice(index, 1);
        setUploadedDocuments(updatedFiles);
    };

    return (
        <div className="">
            {/* Hero Image */}
            <div className="hero">
                <img
                    className="w-full h-48 md:h-64 lg:h-96 object-cover object-center"
                    src={ComplaintPic}
                    alt="Nature Scene"
                />
            </div>

            {/* Form Container */}
            <div className="container mt-8">
                <h1 className="text-2xl md:text-3xl font-bold text-gray-800 uppercase text-center mb-6">
                    Submit a Complaint
                </h1>

                <div className="bg-white px-4 lg:px-8">
                    <p className="font-bold text-gray-700 mb-4">Complaints Handling Procedure</p>
                    <p className="text-gray-600 mb-4">
                        Welcome to the Kenya Land Surveyors Board (LSB). We are committed to
                        upholding the highest standards of professionalism and integrity in
                        the field of land surveying. As part of our dedication to serving
                        the public interest, we have established a robust complaints
                        handling procedure in accordance with the Survey Act Cap 299.
                    </p>

                    <p className="font-bold text-gray-700 mb-4">Submitting a Complaint</p>
                    <p className="text-gray-600 mb-4">
                        If you have any concerns or complaints related to the conduct or
                        services provided by a registered land surveyor, we encourage you to
                        bring them to our attention. You can submit your complaint through
                        the following channels:
                    </p>

                    <div className="mb-4 flex flex-col justify-start items-start">
                        <List
                            requirement="Online Complaint Form"
                            description="Fill out our online complaint form below, providing detailed information about the nature of your complaint and any supporting documentation you may have."
                        />

                        <List
                            requirement="Postal Mail"
                            description="You can also submit your complaint by mail to the following address:"
                        />

                        <div className="block text-center text-gray-500 mt-2 pl-16" style={{ fontSize: 'calc(.5em + .3vw)' }}>
                            Land Surveyors Board <br /> P.O. Box 30046-00100 <br /> Nairobi,
                            Kenya
                        </div>
                    </div>

                    <div className="mb-6 flex flex-col justify-start items-start">
                        <p className="font-bold text-gray-700 mb-4">Procedure</p>

                        <List
                            requirement="Acknowledgement"
                            description="We will acknowledge receipt of your complaint promptly and provide you with a reference number for tracking purposes"
                        />
                        <List
                            requirement="Investigation"
                            description="Our team will thoroughly investigate the allegations raised in your complaint, gathering relevant information and evidence."
                        />
                        <List
                            requirement="Resolution"
                            description="Based on the findings of our investigation, we will take appropriate action to address the issues raised in your complaint. This may include disciplinary measures against the registered land surveyor if misconduct is found."
                        />
                        <List
                            requirement="Communication"
                            description="Throughout the process, we will keep you informed of the progress of your complaint and any outcomes or decisions reached."
                        />
                    </div>

                    <p className="font-bold text-gray-700 mb-4">Confidentiality & Fairness</p>

                    <p className="text-gray-600 mb-6">
                        We treat all complaints with the utmost confidentiality and ensure
                        that the complaints handling process is fair and transparent. We are
                        committed to protecting the rights of both complainants and
                        respondents, and we strive to reach a resolution that is equitable
                        and just.
                    </p>

                    {/* <form onSubmit={handleSubmit} className="">
                        <div className="mb-2">
                            <p className="text-red-800 uppercase font-bold text-center mb-4">ACCUSED *</p>

                            <select
                                value={selectedName}
                                onChange={handleNameChange}
                                className="w-full p-2 border border-gray-300 focus:outline-none focus:border-blue-500"
                                style={{ fontSize: 'calc(.5em + .3vw)' }}
                                required
                            >
                                <option value="">Select A Licensed Surveyor...</option>
                                {names.map((name, index) => (
                                    <option key={index} value={name}>
                                        {name}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <textarea
                            className="w-full p-2 border border-gray-300 focus:outline-none focus:border-blue-500 my-2"
                            placeholder="Nature/ Details & Location of Complaint"
                            rows="5"
                            required
                            style={{ fontSize: 'calc(.5em + .3vw)' }}
                        />

                        <div className="pt-3">
                            <p className="text-red-800 uppercase font-bold text-center">COMPLAINANT *</p>

                            <div className="grid grid-cols-1 md:grid-cols-2 gap-3 mb-3">
                                <input
                                    type="text"
                                    className="w-full p-2 border border-gray-300 focus:outline-none focus:border-blue-500"
                                    placeholder="First Name"
                                    required
                                    style={{ fontSize: 'calc(.5em + .3vw)' }}
                                />

                                <input
                                    type="text"
                                    className="w-full p-2 border border-gray-300 focus:outline-none focus:border-blue-500"
                                    placeholder="Last Name"
                                    required
                                    style={{ fontSize: 'calc(.5em + .3vw)' }}
                                />

                                <input
                                    type="number"
                                    className="w-full p-2 border border-gray-300 focus:outline-none focus:border-blue-500"
                                    placeholder="Phone Number"
                                    required
                                    style={{ fontSize: 'calc(.5em + .3vw)' }}
                                />

                                <input
                                    type="email"
                                    className="w-full p-2 border border-gray-300 focus:outline-none focus:border-blue-500"
                                    placeholder="Email Address"
                                    required
                                    style={{ fontSize: 'calc(.5em + .3vw)' }}
                                />

                                <input
                                    type="text"
                                    className="w-full p-2 border border-gray-300 focus:outline-none focus:border-blue-500"
                                    placeholder="County"
                                    required
                                    style={{ fontSize: 'calc(.5em + .3vw)' }}
                                />

                                <input
                                    type="text"
                                    className="w-full p-2 border border-gray-300 focus:outline-none focus:border-blue-500"
                                    placeholder="Sub-County"
                                    required
                                    style={{ fontSize: 'calc(.5em + .3vw)' }}
                                />
                            </div>
                        </div>

                        <div className="pt-3">
                            <p className="block font-bold text-red-800 uppercase mb-2">SUPPORTING EVIDENCE *</p>

                            <div className="flex items-center justify-center w-full">
                                <label
                                    htmlFor="dropzone-file"
                                    className="flex flex-col items-center justify-center w-full h-48 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100"
                                >
                                    <div className="flex flex-col items-center justify-center px-4">
                                        <i className="fa-solid fa-cloud-arrow-up fa-2x mb-2 text-gray-500"></i>
                                        <small className="text-gray-500">
                                            <span className="font-semibold">Click to upload</span> or drag and drop
                                        </small>
                                        <small className="text-gray-500">DOCX, PNG, JPG, or PDF</small>
                                        <small className="text-gray-500 text-center w-3/5">Ensure any image uploaded is clear and indicates your complaint & maximum of 5 Files</small>
                                    </div>
                                    <input
                                        id="dropzone-file"
                                        type="file"
                                        className="hidden"
                                        accept=".docx,.png,.jpg,.pdf"
                                        onChange={handleFileSelect}
                                        multiple
                                    />
                                </label>
                            </div>

                            {uploadError && (
                                <p className="text-red-500 text-sm mt-2">{uploadError}</p>
                            )}

                            {uploadedDocuments.length > 0 && (
                                <div className="mt-4">
                                    <p className="text-sm font-bold text-gray-700 underline">Uploaded Documents:</p>
                                    <ul className="mt-2">
                                        {uploadedDocuments.map((file, index) => (
                                            <li key={index} className="flex items-center justify-between p-2 border-b border-gray-200">
                                                <span className="text-gray-700">{file.name}</span>
                                                <button
                                                    onClick={() => removeFile(index)}
                                                    className="text-red-600 font-bold hover:text-red-800"
                                                >
                                                    Remove
                                                </button>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                        </div>

                        <div className="py-3 flex">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="flexCheckDefault"
                                required
                            />

                            <p className="form-check-label ml-2" htmlFor="flexCheckDefault">
                                <strong>I confirm that:</strong> I have read, and I understand
                                the complaints procedure and that the information herein
                                submitted is factual.
                            </p>
                        </div>

                        <button className="button mt-4">
                            <span className="button_lg">
                                <span className="button_sl"></span>
                                <span className="button_text">submit</span>
                            </span>
                        </button>
                    </form> */}
                </div>

                {/* <p className="text-gray-600 py-3 px-4 lg:px-2 text-center mx-auto max-w-4xl">
                    Thank you for your cooperation in helping us maintain the integrity
                    and professionalism of the land surveying profession in Kenya.
                </p> */}
            </div>
        </div>
    );
}

export default Complaints;