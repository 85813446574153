import React from 'react';
import CommunityLandAct27 from './Legislation/CommunityLandAct27of2016.pdf';
import LandAct2012 from './Legislation/Land Act, 2012.pdf';
import LandRegistrationAct3of2012 from './Legislation/Land Registration Act 3 of 2012.pdf';
import LandRegistrationRegulations2017 from './Legislation/Land Registration Regulations 2017.pdf';
import CodeofConduct from './Legislation/Licensed Surveyors Code of Professional Conduct, 1997.pdf';
import SectionalPropertiesRegulations2021 from './Legislation/Sectional-Properties-Regulations-2021.pdf';
import SurveyActCAP299 from './Legislation/Survey Act CAP 299.pdf';
import SurveyRegulations1994 from './Legislation/The Survey Regulations, 1994.pdf';
import TheSectionalPropertiesActNo21of2020 from './Legislation/TheSectionalPropertiesAct_No.21of2020.pdf';
import CommunityLandAct27of2016 from './Legislation/CommunityLandAct27of2016.pdf';

const pdfs = [
    { name: "Community Land Act 27 of 2016", file: CommunityLandAct27 },
    { name: "Land Act 2012", file: LandAct2012 },
    { name: "Land Registration Act 3 of 2012", file: LandRegistrationAct3of2012 },
    { name: "Land Registration Regulations 2017", file: LandRegistrationRegulations2017 },
    { name: "Code of Professional Conduct, 1997", file: CodeofConduct },
    { name: "Sectional Properties Regulations 2021", file: SectionalPropertiesRegulations2021 },
    { name: "Survey Act CAP 299", file: SurveyActCAP299 },
    { name: "The Survey Regulations, 1994", file: SurveyRegulations1994 },
    { name: "The Sectional Properties Act 21 of 2020", file: TheSectionalPropertiesActNo21of2020 },
    { name: "Community Land Act 27 of 2016", file: CommunityLandAct27of2016 },
];

const Legislations = () => {
    return (
        <div className="">
            <details className="group [&_summary::-webkit-details-marker]:hidden" closed>
                <summary
                    className="flex cursor-pointer items-center justify-between gap-1.5 rounded-b-lg bg-gray-300 p-2.5 text-gray-900"
                >Legislations <i class="fa-solid fa-chevron-down size-5 shrink-0 transition duration-300 group-open:-rotate-180"></i>
                </summary>

                <div className="py-2 pt-3 flex justify-center gap-2 flex-wrap px-3">
                    {pdfs.map(({ name, file }) => (
                        <div key={name} className="mb-2">
                            <a
                                href={file}
                                rel="noreferrer"
                                target="_blank"
                                className="text-decoration-none text-black border-2 border-gray-400 px-2 py-1 hover:font-medium"
                            >
                                {name}
                            </a>
                        </div>
                    ))}
                </div>
            </details>
        </div>
    );
}

export default Legislations;