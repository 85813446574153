import React from 'react';
import List from '../list';

function IDCards() {
  return (
    <div className="container my-10 px-4">
      <h1 className="font-black leading-none text-gray-800 uppercase dark:text-white text-uppercase text-center my-10">application for identity cards</h1>

      <div className="flex flex-col items-start">
        <List description="Login/Create an Account:" />
        <ul className="mx-10" style={{ listStyleType: 'square' }}>
          <li className="text-sm text-gray-500">Register on the Land Surveyors Board user portal.</li>
        </ul>

        <List description="Provide Information:" />
        <ul className="mx-10" style={{ listStyleType: 'square' }}>
          <li className="text-sm text-gray-500">Input personal details like name, contact information, and professional qualifications.</li>
        </ul>

        <List description="Upload Documents:" />
        <ul className="mx-10" style={{ listStyleType: 'square' }}>
          <li className="text-sm text-gray-500">Scan and upload copies of academic certificates, professional licenses, and identification documents.</li>
        </ul>

        <List description="Fill Application Form:" />
        <ul className="mx-10" style={{ listStyleType: 'square' }}>
          <li className="text-sm text-gray-500">Complete the identity card application form provided on the portal.</li>
        </ul>

        <List description="Pay Fees:" />
        <ul className="mx-10" style={{ listStyleType: 'square' }}>
          <li className="text-sm text-gray-500">Pay the required fees for processing the identity card application online through the portal via E-Citizen Prompt</li>
        </ul>

        <List description="Submit Application:" />
        <ul className="mx-10" style={{ listStyleType: 'square' }}>
          <li className="text-sm text-gray-500">Submit the completed application form and uploaded documents through the user portal.</li>
        </ul>

        <List description="Monitor Status:" />
        <ul className="mx-10" style={{ listStyleType: 'square' }}>
          <li className="text-sm text-gray-500">Keep an eye on the portal for any updates or requests for additional information regarding your application.</li>
        </ul>
      </div>

      <button className="button mt-4" type="submit">
        <span className="button_lg">
          <span className="button_sl"></span>
          <span className="button_text">apply</span>
        </span>
      </button>
    </div>
  )
}

export default IDCards;