import Landlaw1989 from './LandLaw/1989.pdf';
import Landlaw1990 from './LandLaw/1990.pdf';
import Landlaw1991 from './LandLaw/1991.pdf';
import Landlaw1992 from './LandLaw/1992.pdf';
import Landlaw1993 from './LandLaw/1993.pdf';
import Landlaw1994 from './LandLaw/1994.pdf';
import Landlaw1995 from './LandLaw/1995.pdf';
import Landlaw1996 from './LandLaw/1996.pdf';
import Landlaw1997 from './LandLaw/1997.pdf';
import Landlaw1998 from './LandLaw/1998.pdf';
import Landlaw1999 from './LandLaw/1999.pdf';
import Landlaw2000 from './LandLaw/2000.pdf';
import Landlaw2001 from './LandLaw/2001.pdf';
import Landlaw2002 from './LandLaw/2002.pdf';
import Landlaw2003 from './LandLaw/2003.pdf';
import Landlaw2004 from './LandLaw/2004.pdf';
import Landlaw2005 from './LandLaw/2005.pdf';
import Landlaw2006 from './LandLaw/2006.pdf';
import Landlaw2007 from './LandLaw/2007.pdf';
import Landlaw2008 from './LandLaw/2008.pdf';
import Landlaw2009 from './LandLaw/2009.pdf';
import Landlaw2010 from './LandLaw/2010.pdf';

const pdfs = [
    { year: 1989, file: Landlaw1989 },
    { year: 1990, file: Landlaw1990 },
    { year: 1991, file: Landlaw1991 },
    { year: 1992, file: Landlaw1992 },
    { year: 1993, file: Landlaw1993 },
    { year: 1994, file: Landlaw1994 },
    { year: 1995, file: Landlaw1995 },
    { year: 1996, file: Landlaw1996 },
    { year: 1997, file: Landlaw1997 },
    { year: 1998, file: Landlaw1998 },
    { year: 1999, file: Landlaw1999 },
    { year: 2000, file: Landlaw2000 },
    { year: 2001, file: Landlaw2001 },
    { year: 2002, file: Landlaw2002 },
    { year: 2003, file: Landlaw2003 },
    { year: 2004, file: Landlaw2004 },
    { year: 2005, file: Landlaw2005 },
    { year: 2006, file: Landlaw2006 },
    { year: 2007, file: Landlaw2007 },
    { year: 2008, file: Landlaw2008 },
    { year: 2009, file: Landlaw2009 },
    { year: 2010, file: Landlaw2010 },
];

const Dropdown = () => {
    return (
        <div className="">
            <details className="group [&_summary::-webkit-details-marker]:hidden" closed>
                <summary
                    className="flex cursor-pointer items-center justify-between gap-1.5 rounded-b-lg bg-gray-300 p-2.5 text-gray-900"
                >Available Land Law PDFs <i class="fa-solid fa-chevron-down size-5 shrink-0 transition duration-300 group-open:-rotate-180"></i>
                </summary>

                <div className="py-2 flex justify-center gap-2 flex-wrap px-3">
                    {pdfs.map(({ year, file }) => (
                        <div key={year} className="pt-3">
                            <a
                                href={file}
                                rel="noreferrer"
                                target="_blank"
                                className="text-decoration-none text-black border-2 border-gray-400 px-2 py-1 hover:font-medium"
                            >
                                {year}
                            </a>
                        </div>
                    ))}
                </div>
            </details>
        </div>
    );
}

export default Dropdown;