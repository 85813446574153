import React from 'react';
import List from "../list";
import Workflow from '../WorkFlows/WorkFlow';

// guidelines
import RegulationsForm from './Guideline to License Application.pdf';

function SurveyorReg() {
  return (
    <div className="container pt-3 px-4 lg:px-8">
      <h1 className="fw-bold pt-3">REQUIREMENTS FOR LAND SURVEY LICENCE</h1>
      <p className='font-medium py-1 text-[#ff0000]'>Effective From March, 2025</p>

      <p>The following are currently what every surveyor pursuing a land survey license should equip himself/herself with: -</p>

      <div className="pt-2 flex flex-col justify-center items-start">
        <p className='uppercase font-bold pl-5 underline underline-offset-2'>scheme cadastral survey</p>

        <List
          description="A minimum of 10 plots either in one scheme or at most in 3 schemes based on newly established control(s)."
        />

        <p className='uppercase font-bold pl-5 pt-3  underline underline-offset-2'>topographical survey</p>

        <List
          description="Topographical surveys of a minimum of 2 ha for high density areas with over 60% coverage."
        />
        <span className='font-bold pl-16'>OR</span>

        <List
          description="Topographical surveys of a minimum of 4 ha for medium density areas with between 40% and 60%."
        />

        <span className='font-bold pl-16'>OR</span>

        <List
          description="Topographical surveys of a minimum of 10 ha for low density areas with not less than 20% coverage."
        />

        <span className='py-3 pl-16 text-[#ff0000] font-medium' style={{ fontSize: 'calc(.6em + .3vw)' }}>Note: <span className='italic text-gray-600'>Refer to the “Guidelines for topographical surveys” from Land Surveyors’ Board.</span></span>

        <p className='uppercase font-bold pl-5 underline underline-offset-2'>control survey</p>

        <List
          description="If GNSS is used, at least 3 points within an area of about 20 km2  by static mode must be established;"
        />

        <span className='font-bold pl-16'>OR</span>

        <List
          description="For ordinary triangulation/ traverses by theodolite or total stations, at least 4 points must be established."
        />

        <span className='pl-16 text-md pt-1 pb-3 font-medium text-gray-600' style={{ fontSize: 'calc(.6em + .3vw)' }}>However, all the control stations may be done as part of the farm survey/Scheme cadastral provided that they  meet the standard survey requirements for a given survey job.</span>

        <p className='uppercase font-bold pl-5 underline underline-offset-2'>additional requirements</p>

        <span className='pl-8 text-md py-2 font-medium text-gray-600'>Candidates shall also present either production or supervised non- production jobs in the following categories:</span>

        <List
          description="Sectional Property Survey project consisting of at least 30 units"
        />

        <span className='font-bold pl-16'>OR</span>

        <List
          description="A perimeter (farm) survey:"
        />

        <p className='pl-16 text-md py-2 text-gray-600'><span className='font-bold'>I. For production jobs</span> , a surveyor shall generate new perimeter survey beacons of a new parcel of land. The minimum size is 4 ha and must be georeferenced to the existing datum.</p>

        <span className='font-bold pl-16'>OR</span>

        <p className='pl-16 text-md py-2 text-gray-600'><span className='font-bold'>II. For Supervised non-production jobs</span> , all surveys must be georeferenced to the existing datum. The minimum size is 5 ha and must be georeferenced to the existing datum.</p>

        <span className='font-bold pl-16'>OR</span>

        <List
          description="Earth Observation data collection and processing projects in Satellite Remote Sensing, Aerial Photography, UAV, LiDAR and GIS provided a minimum area of 20 ha is covered."
        />

        <span className='font-bold pl-16'>OR</span>

        <List
          description="Setting out works of (not less than 1km in case of linear infrastructure and 0.1 ha in case of buildings). Other structures can also be considered."
        />

        <p className='uppercase font-bold pl-5 py-2 underline underline-offset-2'>Other standard and mandatory requirements:</p>

        <List
          description="Duly completed declaration forms, A & B of the Third Schedule of the Survey Act"
        />

        <List
          description="A pass in the Boards Land Law Examination"
        />

        <List
          description="Full membership of the Institution of Surveyors of Kenya."
        />

        <List
          description="A duly completed application form of the Land Surveyors Board."
        />

        <List
          description="Application letter to the Board."
        />

        <div className='pl-8 pt-4'>
          <span className='py-3 text-[#ff0000] font-bold underline underline-offset-2' style={{ fontSize: 'calc(.5em + .4vw)' }}>Note: </span>

          <ul className='pl-12 list-disc text-gray-500'>
            <li>Candidates who wish to carry out supervised non-production jobs must apply to the Director of Survey for allocation of the site and a supervisor</li>

            <li>All candidates shall be subjected to a mandatory Viva Voce (Oral Examination in surveying) as per schedule 2 of the survey regulations 1994</li>
          </ul>
        </div>
      </div>

      <p className="text-red-500 underline fw-bold mt-3">CAUTION: Ensure all the above requirements are available before you begin the process.</p>

      <div className="my-4">
        <a href={RegulationsForm} className='text-decoration-none font-light hover:font-bold text-white px-4 py-3 bg-black'>Download Form <i class="fa-solid fa-cloud-arrow-down"></i></a>
      </div>

      {/* <Workflow /> */}
    </div>
  )
}

export default SurveyorReg;