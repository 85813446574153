import React from 'react';
import List from "../list";

function ApprovedReg() {
  return (
    <div>
      <div className="container my-12">
        <h1 className="font-black leading-none text-gray-800 uppercase dark:text-white text-uppercase text-center">APPLICATION FOR APPROVED ASSISTANT</h1>

        <div className='py-3 px-4'>
          <p className='font-light'>Requirements for Application as an Approved Assistant through a Licensed Land Surveyor</p>
          <p className='font-light'>Requirements for Submission through Web User Portal:</p>
        </div>

        <div className="flex flex-col gap-2 justify-start items-start">
          <List description="Visit the online portal for Practicing License Renewals" />

          <List description="Log in or (create an account if you are a new user with assistance from LSB Secretariat)" />

          <List description="Fill out the application form with accurate details" />

          <List description="Application Letter: A formal letter addressed to the Secretary, Land Surveyors Board, with the following details:" />

          <ul className="mx-10" style={{ listStyleType: 'square' }}>
            <li className="text-gray-500">Full Name</li>
            <li className="text-gray-500">Contact Information (Address, Phone Number, Email)</li>
            <li className="text-gray-500">Purpose of Application</li>
            <li className="text-gray-500">Any Additional relevant information</li>
          </ul>

          <List description="Passport Size Photo:" />
          <ul className="mx-10" style={{ listStyleType: 'square' }}>
            <li className="text-gray-500">Attach a current passport size photo in JPEG or PNG format</li>
          </ul>

          <List description="Copy of National Identity Card (ID):" />
          <ul className="mx-10" style={{ listStyleType: 'square' }}>
            <li className="text-gray-500">Attach a scanned copy of your National Identity Card (ID) in PDF or image format.</li>
          </ul>

          <List description="Copy of Relevant Certified Academic Certificates:" />
          <ul className="mx-10" style={{ listStyleType: 'square' }}>
            <li className="text-gray-500">Attach scanned copies of all relevant certified academic certificates in PDF format.</li>
          </ul>

          <List description="Copy of Membership Certificate from the Institution of Surveyors of Kenya (ISK):" />
          <ul className="mx-10" style={{ listStyleType: 'square' }}>
            <li className="text-gray-500">Attach a scanned copy of the membership certificate from the Institution of Surveyors of Kenya (ISK) in PDF format.</li>
          </ul>

          <List description="Copy of Certificate of Good Standing from the Institution of Surveyors of Kenya:" />
          <ul className="mx-10" style={{ listStyleType: 'square' }}>
            <li className="text-gray-500">Attach a scanned copy of the certificate of good standing from the Institution of Surveyors of Kenya in PDF format.</li>
          </ul>

          <List description="Affidavit Administered by Commissioner for Oaths:" />
          <ul className="mx-10" style={{ listStyleType: 'square' }}>
            <li className="text-gray-500">Attach a scanned copy of the affidavit administered by the Commissioner for Oaths in PDF format.</li>
          </ul>

          <List description="Application Fee Payment:" />
          <ul className="mx-10" style={{ listStyleType: 'square' }}>
            <li className="text-gray-500">Payment of Two Thousand Five Hundred Kenya Shillings (KSh. 2,550.00) via M-PESA paybill to E-Citizen Land Surveyors’ Board</li>
            <li className="text-gray-500">Pay the required fees through the portal via E-Citizen Prompt</li>
          </ul>
        </div>
      </div>

      <div className="container text-center px-4">
        <p>Please ensure that all documents are clear, legible, and accurately represent the information required for your application.</p>
        <p className="font-bold">Incomplete submissions may delay the processing of your application.</p>

        <button className="button mt-2" type="submit">
          <span className="button_lg">
            <span className="button_sl"></span>
            <span className="button_text">apply</span>
          </span>
        </button>
      </div>
    </div>
  )
}

export default ApprovedReg;