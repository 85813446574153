import React from "react";

function Report() {
  return (
    <div className="container px-4 lg:px-8">
      <div className="py-5 flex flex-col justify-center items-center text-center">
        <h1 className="font-bold leading-none text-gray-800 uppercase dark:text-white">
          Further Engagement
        </h1>

        <p>
          We also encourage reporting of any offense you have witnessed that
          might cause harm or danger to the public. Kindly forward the offense
          to{" "} <a href="mailto:info@lsb.go.ke" target="_blank" rel="noreferrer" className="fw-bold">info@lsb.go.ke</a>{" "}
          for action to be taken.</p>

        <p>Thank you for your cooperation in helping us maintain the integrity
          and professionalism of the land surveying profession in Kenya.</p>
      </div>
    </div>
  );
}

export default Report;
