import { Carousel } from 'react-bootstrap';
import './Home.css';
import ExampleCarouselImage1 from './images/back01.png';
import ExampleCarouselImage2 from './images/back02.png';
import ExampleCarouselImage3 from './images/back03.jpg';

// Common caption for all carousel items
const commonCaption = {
  title: ['Ensuring Ethical Surveying Practices', 'Certification and Continuing Education', 'Quality Assurance in Surveying'],
  description: ['Nulla vitae elit libero a pharetra augue mollis interdum.', 'Nulla vitae elit libero.', 'Pharetra augue mollis interdum.'],
};

function HeroCarousel() {
  return (
    <Carousel interval={3000} pause={false} controls={false} indicators={false}>
      <Carousel.Item>
        <img src={ExampleCarouselImage1} alt="First slide" className="carousel-image w-100" />

        <Carousel.Caption className='carousel-caption'>
          <div className='px-8 lg:px-16'>
            <h3>{commonCaption.title[0]}</h3>
            {/* <p className='italic'>{commonCaption.description[0]}</p> */}
          </div>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item>
        <img src={ExampleCarouselImage2} alt="Second slide" className="carousel-image w-100" />

        <Carousel.Caption className='carousel-caption'>
          <div className='px-8 lg:px-16'>
            <h3>{commonCaption.title[1]}</h3>
            {/* <p className='italic'>{commonCaption.description[1]}</p> */}
          </div>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item>
        <img src={ExampleCarouselImage3} alt="Third slide" className="carousel-image w-100" />

        <Carousel.Caption className='carousel-caption'>
          <div className='px-8 lg:px-16'>
            <h3>{commonCaption.title[2]}</h3>
            {/* <p className='italic'>{commonCaption.description[2]}</p> */}
          </div>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
}

export default HeroCarousel;