import React, { useState, useEffect } from "react";
import axios from "axios";

const PractisingCh = () => {
    const [userData, setUserData] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [nextPage, setNextPage] = useState(null);
    const [previousPage, setPreviousPage] = useState(null);
    const accessToken = localStorage.getItem("accessToken");
    const accountType = localStorage.getItem("account_type");
    const currentYear = new Date().getFullYear(); // Automatically pick the current year

    useEffect(() => {
        if (accountType === "chair") {
            fetchData("https://portal.lsb.go.ke/account/practising/");
        }
    }, [accountType]);

    const fetchData = async (url) => {
        if (!accessToken) {
            setError("Access token not found. Please log in.");
            return;
        }

        try {
            setLoading(true);
            const response = await axios.get(url, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });

            const { results, links } = response.data;
            setUserData(results);
            setNextPage(links.next);
            setPreviousPage(links.previous);
            setError(null);
        } catch (error) {
            setError("Failed to fetch user data. Please try again.");
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    };

    // normal user rendering
    const [goodStandingCertificate, setGoodStandingCertificate] = useState(null);
    const [otherDocument, setOtherDocument] = useState(null);
    const [errorMessage, setErrorMessage] = useState("");

    const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB

    const handleFileChange = (e, setter) => {
        const file = e.target.files[0];
        if (file) {
            if (file.type !== "application/pdf") {
                setErrorMessage("Please upload a PDF file.");
            } else if (file.size > MAX_FILE_SIZE) {
                setErrorMessage("File size must not exceed 5MB.");
            } else {
                setter(file);
                setErrorMessage(""); // Clear error on valid file
            }
        }
    };

    const checkExistingRecord = async () => {
        try {
            const response = await axios.get(`https://portal.lsb.go.ke/lsb/certification/?year=${currentYear}&licensed=${userData?.license_no}`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });

            if (response.data.length > 0) {
                setErrorMessage("A record for this year already exists.");
                return false;
            }
        } catch (error) {
            console.error("Error checking existing record:", error);
        }
        return true;
    };

    const handleSubmit = async () => {
        if (!goodStandingCertificate || !otherDocument) {
            setErrorMessage("Please upload both documents.");
            return;
        }

        const canSubmit = await checkExistingRecord();
        if (!canSubmit) return;

        try {
            setLoading(true);
            const formData = new FormData();
            formData.append('goodStandingCertificate', goodStandingCertificate);
            formData.append('otherDocument', otherDocument);
            formData.append('year', currentYear);
            formData.append('licensed', userData?.license_no);

            const response = await axios.put('https://portal.lsb.go.ke/lsb/certification/', formData, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.status === 200) {
                setErrorMessage("Documents uploaded successfully.", "success");
            } else {
                throw new Error("Failed to upload documents.");
            }
        } catch (error) {
            if (error.response) {
                console.error("Server Error:", error.response.data);
                setErrorMessage(`Server error: ${error.response.data.detail || "Something went wrong."}`);
            } else if (error.request) {
                console.error("No response received:", error.request);
                setErrorMessage("No response from the server. Please check your internet connection.");
            } else {
                console.error("Request setup error:", error.message);
                setErrorMessage("Failed to upload documents. Please try again.");
            }
        } finally {
            setLoading(false);
        }
    };


    const renderTableForChair = () => (
        <>
            <table className="w-full bg-white border border-gray-300">
                <thead>
                    <tr className="bg-black text-white">
                        <th className="p-3 border text-sm">Name</th>
                        <th className="p-3 border text-sm">License</th>
                        <th className="p-3 border text-sm">Good Standing</th>
                        <th className="p-3 border text-sm">Year</th>
                        <th className="p-3 border text-sm">Status</th>
                        <th className="p-3 border text-sm">Actions</th>
                    </tr>
                </thead>

                <tbody>
                    {userData.map((entry, index) => {
                        const { supervisor, year, status } = entry;
                        return (
                            <tr key={index} className="">
                                <td className="p-3 border text-sm">{supervisor.name}</td>
                                <td className="p-3 border text-sm">{supervisor.license_no}</td>
                                <td className="p-3 border text-sm">{supervisor.good_standing}</td>
                                <td className="p-3 border text-sm">{year}</td>
                                <td className="p-3 border text-sm">{status}</td>
                                <td className="p-3 border text-sm">
                                    {/* Add any specific actions for the "chair" */}
                                    <button className="bg-black text-white px-2 py-1 cursor-pointer">Update</button>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>

            <div className="flex gap-3 mt-4">
                <button
                    onClick={() => fetchData(previousPage)}
                    disabled={!previousPage}
                    className={`button ${previousPage ? "bg-blue-500 text-white" : "bg-gray-300 text-gray-500"
                        }`}
                >
                    <span className="button_lg">
                        <span className="button_sl"></span>
                        Previous
                    </span>
                </button>

                <button
                    onClick={() => fetchData(nextPage)}
                    disabled={!nextPage}
                    className={`button ${nextPage ? "bg-blue-500 text-white" : "bg-gray-300 text-gray-500"
                        }`}
                >
                    <span className="button_lg">
                        <span className="button_sl"></span>
                        Next
                    </span>
                </button>
            </div>
        </>
    );

    const renderForNormalUser = () => (
        <div>
            <h4 className="font-medium mb-3">Document Uploads</h4>

            <div className="space-y-6">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 lg:gap-6">
                    <div className="flex flex-col gap-2 border p-3 rounded-md shadow-xl bg-white">
                        <label htmlFor="goodStandingCertificate" className="text-sm font-medium" style={{ fontSize: 'calc(.5em + .4vw)' }}>
                            Upload Good Standing Certificate
                        </label>

                        <input
                            className="cursor-pointer"
                            type="file"
                            id="good_standing"
                            onChange={(e) => handleFileChange(e, setGoodStandingCertificate)}
                            style={{ fontSize: 'calc(.5em + .3vw)' }}
                        />
                    </div>

                    <div className="flex flex-col gap-2 border p-3 rounded-md shadow-xl bg-white">
                        <label htmlFor="otherDocument" className="text-sm font-medium" style={{ fontSize: 'calc(.5em + .4vw)' }}>
                            Upload Other Document
                        </label>

                        <input
                            className="cursor-pointer"
                            type="file"
                            id="additional_attachment"
                            onChange={(e) => handleFileChange(e, setOtherDocument)}
                            style={{ fontSize: 'calc(.5em + .3vw)' }}
                        />
                    </div>
                </div>

                {errorMessage && <p className={`text-${errorMessage.type === "success" ? "green-500" : "red-500"} text-sm`}>{errorMessage.message}</p>}

                <button className="button mt-4" onClick={handleSubmit}>
                    <span className="button_lg">
                        <span className="button_sl"></span>
                        <span className="button_text" style={{ fontSize: 'calc(.5em + .3vw)' }}>Submit</span>
                    </span>
                </button>
            </div>
        </div>
    );

    // Main render logic
    return (
        <div className="p-2">
            <p className="font-medium mb-3 underline underline-offset-2 uppercase">practising license renewal</p>

            {error && <div className="text-red-500 bg-red-100 p-2 rounded mb-4">{error}</div>}

            {accountType !== "chair"
                ? renderForNormalUser()
                : loading
                    ? <div className="text-center text-gray-500">Loading...</div>
                    : renderTableForChair()}
        </div>
    );
};

export default PractisingCh;