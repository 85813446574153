import React from 'react';
import ComplimentPic from "./compliment.webp";

function Compliments() {
    return (
        <div className="">
            <div className="hero">
                <img
                    className="h-96 w-full object-cover object-center"
                    src={ComplimentPic}
                    alt="Nature Scene"
                />
            </div>

            <div className="container my-3">
                <h1 className="flex flex-col font-black leading-none text-gray-800 uppercase dark:text-white text-center">
                    Applaud Good Work
                </h1>

                <div className="container my-1.5">
                    <p className="text-center">
                        We would love to hear about the outstanding professionals that adhere to the standards of the board, offer exemplary services to the public, and are upholding the good name of the board.
                    </p>

                    {/* <form className="p-2">
                    <div className=" relative ">
                            <input type="text" id="name-with-label" className=" rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent" name="name" placeholder="Your Full Name" />
                        </div>

                        <div className=" relative mt-2">
                            <input type="text" id="name-with-label" className=" rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent" name="name" placeholder="Name of Surveyor" />
                        </div>


                        <div className="mt-3">
                            <textarea className="flex-1 w-full px-4 py-2 text-base text-gray-700 placeholder-gray-400 bg-white border border-gray-300 rounded-lg appearance-none focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent" id="complaint" placeholder="Nature of Work" name="comment" rows="5" cols="40">
                            </textarea>
                        </div>

                        <div className="form-check mt-3">
                            <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                            <label className="form-check-label" for="flexCheckDefault">
                                <strong>I confirm that:</strong> I have read, and I understand the content added above and is factual as to my knowledge.
                            </label>
                        </div>

                        <button className="button mt-4">
                            <span className="button_lg">
                                <span className="button_sl"></span>
                                <span className="button_text">submit</span>
                            </span>
                        </button>
                    </form> */}
                </div>
            </div>
        </div>
    )
}

export default Compliments;