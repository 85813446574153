import React from 'react'

function SurveyCourse() {
  return (
    <div className="container my-10 px-4">
      <h1 className="font-black leading-2 text-gray-800 uppercase dark:text-white text-uppercase text-center">Accredited Land Surveying University Programs for Registration as a Licensed Surveyor</h1>

      <div className="flex justify-center items-center mt-3" style={{ width: "85vw" }}>
        <table border="1" className="table table-striped border-t" style={{ width: '100%' }}>
          <thead className="bg-gray-600 text-white">
            <tr>
              <th className="">No.</th>
              <th className="">University</th>
              <th className="">Course</th>
            </tr>
          </thead>

          <tbody>
          <tr>
              <td className="px-3">1.</td>
              <td className=""></td>
              <td className=""></td>
            </tr>

            <tr>
              {/* <td className="px-3">1.</td>
              <td className="">University of Nairobi</td>
              <td className="">Bachelor of Science in Geospatial Engineering</td> */}
            </tr>

            <tr>
              {/* <td className="px-3">2.</td>
              <td className="">University of Nairobi</td>
              <td>Bachelor of Science in Surveying and Photogrammetry</td> */}
            </tr>

            <tr>
              {/* <td className="px-3">3.</td>
              <td className="">University of Nairobi</td>
              <td>Bachelor of Science in Surveying</td> */}
            </tr>

            <tr>
              {/* <td className="px-3">4.</td>
              <td className="">Technical University of Kenya</td>
              <td>Bachelor of Engineering in Geospatial Engineering</td> */}
            </tr>

            <tr>
              {/* <td className="px-3">5.</td>
              <td className="">Technical University of Kenya</td>
              <td>Bachelor of Technology in Surveying Technology</td> */}
            </tr>

            <tr>
              {/* <td className="px-3">6.</td>
              <td className="">Jomo Kenyatta University of Science and Technology</td>
              <td>Bachelor of Science in Geomatics Engineering and Geospatial Information Systems</td> */}
            </tr>

            <tr>
              {/* <td className="px-3">7.</td>
              <td className="">Dedan Kimathi University of Technology</td>
              <td>Bachelor of Science in Geomatics Engineering and Geospatial Information Systems</td> */}
            </tr>
          </tbody>
        </table>
      </div>

      <h1 className="font-black leading-2 text-gray-800 uppercase dark:text-white text-uppercase text-center pt-3">Accredited Land Surveying Technician Programs for Registration as approved assistants</h1>

      <div className="flex justify-center items-center mt-3" style={{ width: "85vw" }}>
        <table border="1" className="table table-striped border-t" style={{ width: '100%' }}>
          <thead className="bg-gray-600 text-white">
            <tr>
              <th className="">No.</th>
              <th className="">University/ College</th>
              <th className="">Course</th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td className="px-3">1.</td>
              <td className=""></td>
              <td className=""></td>
            </tr>
          </tbody>
        </table>
      </div>

      <p className="mt-4">For any clarification on this please contact: <span className="text-blue-600 fw-bold underline">inquiry@lsb.go.ke</span></p>
    </div>
  )
}

export default SurveyCourse;