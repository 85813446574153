import TopgraphicalSurvey from './Guidelines/Topographical Survey Guidelines.pdf';
import LicenseApplication from './Guidelines/Guideline to License Application.pdf';

const Guidelines = () => {
    return (
        <div className="">
            <details className="group [&_summary::-webkit-details-marker]:hidden" closed>
                <summary
                    className="flex cursor-pointer items-center justify-between gap-1.5 rounded-b-lg bg-gray-300 p-2.5 text-gray-900"
                >Guidelines <i class="fa-solid fa-chevron-down size-5 shrink-0 transition duration-300 group-open:-rotate-180"></i>
                </summary>

                <div className="py-2 pt-3 flex justify-center gap-2 flex-wrap px-3">
                    <a href={TopgraphicalSurvey} rel='noreferrer' target='_blank' className="text-decoration-none text-black border-2 border-gray-400 px-2 py-1 hover:font-medium">Topographical Survey</a>

                    <a href={LicenseApplication} rel='noreferrer' target='_blank' className="text-decoration-none text-black border-2 border-gray-400 px-2 py-1 hover:font-medium">Guidelines to License Application</a>
                </div>
            </details>
        </div>
    );
}

export default Guidelines;