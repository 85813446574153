import React, { useState, useEffect } from "react";

const backendUrl = "https://portal.lsb.go.ke/account/approved/";

function CurrentRegister() {
  const [assistants, setAssistants] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage] = useState(20); // Number of items per page

  // Fetch data from the backend with pagination (limit and offset) and search
  const fetchData = async (page, searchTerm) => {
    setLoading(true);
    const offset = (page - 1) * itemsPerPage;
    try {
      const response = await fetch(`${backendUrl}?limit=${itemsPerPage}&offset=${offset}&search=${searchTerm}`);
      const data = await response.json();
      if (data.results.length === 0) {
        setAssistants([]); // Clear assistants if user does not exist
        console.error("User does not exist");
      } else {
        setAssistants(data.results.filter(assistant => assistant.assistant_id.includes(searchTerm))); // Filter based on assistant_id
        setTotalPages(Math.ceil(data.count / itemsPerPage)); // Update total pages based on the total count
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  // Fetch data when component mounts or when currentPage or searchQuery changes
  useEffect(() => {
    fetchData(currentPage, searchQuery);
  }, [currentPage, searchQuery]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <div>
      <div className="relative overflow-x-auto">
        <label
          htmlFor="approvedAssistants"
          className="relative block overflow-hidden border-b border-gray-200 bg-transparent pt-3 focus-within:border-blue-600"
        >
          <input
            type="text"
            placeholder=""
            className="peer h-12 w-full border-none bg-gray-200 px-4 placeholder-transparent focus:border-transparent focus:outline-none focus:ring-0 rounded-t-lg fw-bold"
            value={searchQuery}
            onChange={handleSearchChange}
            autoFocus // This line ensures the input is active to allow the whole input instead of only just a single letter
          />

          <span
            className="absolute start-0 top-2 -translate-y-1/2 text-xs text-gray-800 transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-sm peer-focus:top-2 peer-focus:text-xs px-4"
          >
            Search by Assistant ID...
          </span>
        </label>
      </div>

      {loading ? (
        <div>Loading...</div>
      ) : (
        <div className="overflow-x-auto mt-2">
          <table className="w-full text-sm text-left rtl:text-right table table-striped">
            <thead className="uppercase">
              <tr>
                <th scope="col" className="py-3 px-3 bg-black text-white">
                  APPROVED ASST.
                </th>
                <th scope="col" className="py-3 px-3 bg-black text-white">
                  ASSISTANT No.
                </th>
                <th scope="col" className="py-3 px-3 bg-black text-white">
                  SUPERVISED BY
                </th>
                <th scope="col" className="py-3 px-3 bg-black text-white">
                  SUPERVISOR LICENSE
                </th>
              </tr>
            </thead>
            <tbody>
              {assistants.length > 0 ? (
                assistants.map((assistant, index) => (
                  <tr key={index}>
                    <td className="py-2 px-3">{assistant.assistant_name}</td>
                    <td className="px-3">{assistant.assistant_no}</td>
                    <td className="px-3">{assistant.supervisor_name}</td>
                    <td className="px-3">{assistant.supervisor_license}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="4">No assistants found.</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}

      <span style={{ fontSize: 'calc(.7em + .3vw)' }} className="italic font-medium">Page {currentPage} of {totalPages}</span>

      <div className="flex justify-start items-center gap-2 pt-2">
        <button className="button mt-4" onClick={handlePreviousPage}
          disabled={currentPage === 1}>
          <span className="button_lg">
            <span className="button_sl"></span>
            <span className="button_text" style={{ fontSize: 'calc(.5em + .3vw)' }}>previous</span>
          </span>
        </button>

        <button className="button mt-4" onClick={handleNextPage}
          disabled={currentPage === totalPages}>
          <span className="button_lg">
            <span className="button_sl"></span>
            <span className="button_text" style={{ fontSize: 'calc(.5em + .3vw)' }}>next</span>
          </span>
        </button>
      </div>
    </div>
  );
}

export default CurrentRegister;