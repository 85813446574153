import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Link, useNavigate } from 'react-router-dom';
import './Dashboard.css';
import Swal from 'sweetalert2'; // Import Swal for sweetalert2 functionality
import PractisingCh from './PractisingCh';
import AssistantsTab from './AssistantsTab';

const FinalDashboardL = () => {
    const [activeTab, setActiveTab] = useState('Licenses');
    const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth >= 1024);
    const [userDetails, setUserDetails] = useState(null); // Start with null to indicate loading
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    // Dynamically update sidebar state on window resize
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth >= 1024) {
                setIsSidebarOpen(true); // Keep sidebar open on larger screens
            } else {
                setIsSidebarOpen(false); // Close sidebar on smaller screens
            }
        };

        window.addEventListener('resize', handleResize); // Add event listener
        return () => window.removeEventListener('resize', handleResize); // Cleanup listener on unmount
    }, []);

    // Fetch user details on component mount
    useEffect(() => {
        const fetchData = async () => {
            const accessToken = localStorage.getItem('accessToken'); // Corrected to 'accessToken'
            if (!accessToken) {
                navigate('/portal');
                return;
            }

            try {
                setLoading(true);
                setError(null);

                const response = await fetch('https://portal.lsb.go.ke/account/me', {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${accessToken}`, // Corrected to 'accessToken'
                        'Content-Type': 'application/json',
                    },
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch user data');
                }

                const data = await response.json();
                //console.log('User Data', data); // Log user data to assess information from the server
                setUserDetails(data); // Update userDetails state with fetched data
            } catch (error) {
                console.error('Error fetching user details:', error);
                setError(error.message);
                navigate('/portal');
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [navigate]);

    // Content for each tab
    const renderContent = () => {
        switch (activeTab) {
            case 'Licenses':
                return <div className="">
                    <PractisingCh />
                </div>;
            case 'ID Badge':
                return <div className="">
                    <p className='font-medium'>Apply for LSB Id Card. Track readiness and when to collect.</p>
                    <p className='font-bold'>Coming Soon</p>
                </div>;
            case 'Assistants':
                return <div className="">
                    <AssistantsTab />
                </div>;
            case 'Events':
                return <div className="">
                    <p className='font-light'>Apply for an Event.</p>
                </div>;
            case 'Complaint':
                return <div className="">
                    <p className='font-light'>File and view complaints.</p>
                </div>;
            case 'Help Center':
                return <div className="">
                    <p className='font-light'>Get help and find answers to FAQs.</p>
                </div>;
            default:
                return <div className="">
                    <h2 className='font-light'>Welcome to the dashboard!</h2>
                </div>;
        }
    };

    const handleLogout = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You will be logged out.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, log out!'
        }).then((result) => {
            if (result.isConfirmed) {
                localStorage.removeItem('accessToken'); // Corrected to 'accessToken'
                navigate('/portal');
                Swal.fire(
                    'Logged out!',
                    'You have been logged out.',
                    'success'
                );
            }
        });
    };

    const profilePicUrl = userDetails?.user?.profile_pic?.startsWith('http')
        ? userDetails.user.profile_pic
        : `https://portal.lsb.go.ke/media/${userDetails?.user?.profile_pic}`;

    return (
        <div className="flex flex-col lg:flex-row min-h-screen bg-gray-100">
            {/* Hamburger Menu Button */}
            <button
                className="lg:hidden bg-gray-700 text-white text-end p-3 shadow-md static top-85 right-0"
                onClick={() => setIsSidebarOpen(!isSidebarOpen)}
            >
                <i className={`fas ${isSidebarOpen ? 'fa-times' : 'fa-bars'} text-xl`}></i>
            </button>

            {/* Sidebar */}
            <motion.nav
                initial={{ x: -300 }}
                animate={{ x: isSidebarOpen || window.innerWidth >= 1024 ? 0 : -300 }}
                transition={{ duration: 0.5 }}
                className={`bg-white shadow-lg lg:min-h-screen min-w-[30px] py-6 px-4 flex flex-col fixed lg:static top-85 left-0`}
            >
                {/* User Info */}
                <div className="flex flex-col items-center cursor-pointer space-y-2 py-3">
                    <div className="relative">
                        {/* profile_pic */}
                        <img
                            src={profilePicUrl}
                            onError={(e) => { e.target.src = require('./defaultProfilePic.png'); }}
                            className="w-24 h-24 rounded-full border-white object-cover"
                            alt="userImg"
                        />
                    </div>

                    <div className="ml-4 flex flex-col text-center">
                        {/* name */}
                        <span className="text-md text-[#3949ab] font-semibold">{userDetails?.user?.name || 'John Doe'}</span>
                        <span className="text-sm">{userDetails?.user?.status || 'Surveyor'}</span>
                    </div>
                </div>

                <div className='flex flex-col justify-between'>
                    {/* Navigation Links */}
                    <div className="space-y-6">
                        {[
                            { name: 'Licenses', icon: 'fa-certificate' },
                            { name: 'ID Badge', icon: 'fa-id-badge' },
                            { name: 'Assistants', icon: 'fa-user' },
                            { name: 'Events', icon: 'fa-calendar-check' },
                            { name: 'Complaint', icon: 'fa-thumbs-down' },
                            { name: 'Help Center', icon: 'fa-circle-question' },
                        ].map((tab) => (
                            <Link key={tab.name} className='text-decoration-none flex flex-col gap-1'>
                                <button
                                    onClick={() => {
                                        setActiveTab(tab.name);
                                        setIsSidebarOpen(false); // Close sidebar after selecting a tab (mobile only)
                                    }}
                                    className={`text-black text-sm flex items-center gap-4 rounded-md px-3 py-2 hover:font-semibold transition ${activeTab === tab.name ? 'font-semibold bg-gray-200' : ''
                                        }`}
                                >
                                    <i className={`fa ${tab.icon}`}></i>
                                    <span>{tab.name}</span>
                                </button>
                            </Link>
                        ))}
                    </div>

                    {/* Logout */}
                    <div className="mt-8">
                        <div className='border-t-2 border-gray-500 border-dotted py-2'></div>

                        <button className="text-black text-sm flex items-center gap-4 rounded-md px-3 py-3 hover:text-white hover:font-semibold transition w-full" onClick={handleLogout}>
                            <i className="fa-solid fa-power-off"></i>
                            <span>Logout</span>
                        </button>
                    </div>
                </div>
            </motion.nav>

            {/* Main Content */}
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
                className={`flex-1 p-6 lg:ml-[30px] ${isSidebarOpen ? 'opacity-20 pointer-events-none' : ''}`} // Disable content when sidebar is open on small screens
            >
                <h2 className="font-semibold text-gray-700 border-b pb-2 uppercase">{activeTab}</h2>
                <div className="mt-4">{renderContent()}</div>
            </motion.div>
        </div>
    );
};

export default FinalDashboardL;