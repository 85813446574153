import { React, useState } from 'react';
import "./navbar.css";
import { CNav, CNavLink, CNavItem, CDropdown, CDropdownToggle, CDropdownMenu, CDropdownItem } from '@coreui/react';
import Logo from "../Assets/lsbLogo.png";

function Navbar() {
  const [visible, setVisible] = useState(false)

  // Function to toggle the visibility of the menu
  const toggleMenu = () => {
    setVisible(!visible);
  };

  return (
    <div className="container-fluid">
      {/* logo */}
      <div id='logo_container'>
        <CNavLink href='/'>
          <img src={Logo} alt="LOGO" id="logo" />
          <p className="font-bold uppercase">land surveyors' board</p>
        </CNavLink>
      </div>

      {/* Hamburger menu for mobile */}
      <button onClick={toggleMenu} className="hamburger-menu my-3">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
          <path d="M3 12h18M3 6h18M3 18h18"></path>
        </svg>
      </button>

      {/* navlinks */}
      <CNav className={`justify-content-end container-fluid ${visible ? 'show-navbar' : 'hide-navbar'}`}>
        <CNavItem>
          <CNavLink href="/">Home</CNavLink>
        </CNavItem>

        <CNavItem>
          <CNavLink href="/about">About</CNavLink>
        </CNavItem>

        <CNavItem>
          <CNavLink href="/register">Register</CNavLink>
        </CNavItem>

        <CNavItem>
          <CNavLink href="/resources">Resources</CNavLink>
        </CNavItem>

        <CDropdown variant="nav-item" popper={false}>
          <CDropdownToggle>Services</CDropdownToggle>

          <CDropdownMenu className='navItemsDropdown'>
            <CDropdownItem href="/registration" className='dropItem'>Fees</CDropdownItem>
            <CDropdownItem href="/surveyorreg" className='dropItem'>Registration as a Licensed Surveyor</CDropdownItem>
            <CDropdownItem href="/annuallicence" className='dropItem'>Renewal of Annual Practicing License</CDropdownItem>
            <CDropdownItem href="/approvedreg" className='dropItem'>Application for Approved Assistants</CDropdownItem>
            <CDropdownItem href="/idcards" className='dropItem'>Application for Identity Cards</CDropdownItem>
            <CDropdownItem href="/eventsreg" className='dropItem'>Registration for Events</CDropdownItem>
            <CDropdownItem href="/landlaw" className='dropItem'>Application to Sit for Land Law</CDropdownItem>
            <CDropdownItem href="/surveycourse" className='dropItem'>Accreditation of Surveying Programs</CDropdownItem>
            {/* <CDropdownItem href="/continuingDev">Continuing Professional Development</CDropdownItem> */}
          </CDropdownMenu>
        </CDropdown>

        <CNavItem>
          <CNavLink href="/portal">Surveyor's Portal</CNavLink>
        </CNavItem>

        <CDropdown variant="nav-item" popper={false}>
          <CDropdownToggle>Feedback</CDropdownToggle>

          <CDropdownMenu className='navItemsDropdown'>
            <CDropdownItem href="/compliments" className='dropItem'>Compliments</CDropdownItem>
            <CDropdownItem href="/inquiries" className='dropItem'>Inquiries</CDropdownItem>
            <CDropdownItem href="/complaints" className='dropItem'>Complaints</CDropdownItem>
            <CDropdownItem href="/report" className='dropItem'>Report an Offense</CDropdownItem>
          </CDropdownMenu>
        </CDropdown>

        <CNavItem>
          <CNavLink href="/contact">Contact Us</CNavLink>
        </CNavItem>
      </CNav>
    </div>
  )
}

export default Navbar;