import React from 'react';

function FacebookWidget() {
  return (
    <div className="mt-5">
      <iframe
        src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fprofile.php%3Fid%3D61558428002052&tabs=timeline&width=385&height=385&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
        width="385"
        height="385"
        style={{ border: 'none', overflow: 'hidden' }}
        scrolling="no"
        frameBorder="0"
        allowFullScreen={true}
        allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
        title='facebook'
      ></iframe>
    </div>
  );
}

export default FacebookWidget;