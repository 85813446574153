import React from "react";
import "./Footer.css";
import LsbLogo from "../Assets/lsbLogo.png";
import Facebook from "./facebook.png";
import Twitter from "./Xtwitter.png";
import LinkedIn from "./linkedin.png";
import { NavLink } from "react-router-dom";

function Footer() {
  const CurrentYear = () => {
    const year = new Date().getFullYear();
    return <span>{year}</span>;
  };

  return (
    <div className="bg-gray-600">
      <footer className="footer-section bg-black">
        <div className="px-8">
          {/* contact details */}
          <div className="grid grid-cols-1 gap-4 lg:grid-cols-3 lg:gap-8 pt-4">
            {[
              { icon: "fas fa-phone", title: "Call us", content: "+254 799 093 219" },
              { icon: "fas fa-map-marker-alt", title: "Find us", content: "Land Surveyors Board, Survey of Kenya Headquarters, P.O. Box 30046-00100, Ruaraka Nairobi- Kenya" },
              { icon: "far fa-envelope", title: "Mail us", content: "info@lsb.go.ke" }
            ].map((item, index) => (
              <div key={index} className="flex flex-col gap-1 items-center text-center cursor-pointer">
                {item.icon === "fas fa-map-marker-alt" ? (
                  <NavLink exact to="/contact"><i className={`${item.icon} text-blue-300 fa-2x hover:text-white`}></i></NavLink>
                ) : (
                  <i className={`${item.icon} text-blue-300 fa-2x hover:text-white`}></i>
                )}
                <div>
                  <p className="text-white fw-bold">{item.title}</p>
                  <p className="text-white">{item.content}</p>
                </div>
              </div>
            ))}
          </div>

          <div className="bg-white h-0.5 my-4"></div>

          {/* footer summary */}
          <div className="footer-content pt-2 pb-3">
            <div className="grid grid-cols-1 gap-5 lg:grid-cols-3 lg:gap-8">
              <div className="flex flex-col justify-center">
                <div className="footer-widget">
                  <div className="flex justify-center items-center my-3">
                    <a href="/">
                      <img src={LsbLogo} className="img-fluid" alt="logo" style={{ width: 'calc(13em + 2vw)' }} />
                    </a>
                  </div>

                  <div className="text-center mx-4">
                    <p className="text-gray-200 leading-6 font-light">
                      The Land Surveyors Board was founded in 1923 and operates
                      under the authority of the Survey Act Cap 299 of the Laws
                      of Kenya. We regulate land surveyors' practice to ensure
                      professionalism and uphold industry standards within the
                      Republic of Kenya.
                    </p>
                  </div>

                  <div className="flex flex-col justify-center items-center gap-3 mx-3">
                    <small className="text-white">Engage with us on:</small>

                    <div className="flex gap-3">
                      <a href="https://www.facebook.com/profile.php?id=61558428002052" target="_blank" rel="noreferrer" className="text-white">
                        <img alt="linkedIn" src={Facebook} />
                      </a>

                      <a href="https://twitter.com/kenya_lsb" target="_blank" rel="noreferrer" className="text-white">
                        <img alt="linkedIn" src={Twitter} />
                      </a>

                      <a href="https://www.linkedin.com/company/land-surveyors-board-ken" target="_blank" rel="noreferrer" className="text-white">
                        <img alt="linkedIn" src={LinkedIn} />
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex flex-col">
                <div className="footer-widget">
                  <h3 className="text-white font-bold uppercase underline underline-offset-4">Useful Links</h3>

                  <div className="flex flex-col">
                    <a
                      href="https://ardhisasa.lands.go.ke/home"
                      target="_blank"
                      rel="noreferrer"
                      className="text-gray-400 text-decoration-none hover:text-white"
                    >
                      Ardhisasa
                    </a>

                    <a
                      href="https://lands.go.ke/"
                      target="_blank"
                      rel="noreferrer"
                      className="text-gray-400 text-decoration-none hover:text-white"
                    >
                      State Department for Lands & Physical Planning
                    </a>

                    <a
                      href="https://isk.or.ke/"
                      target="_blank"
                      rel="noreferrer"
                      className="text-gray-400 text-decoration-none hover:text-white"
                    >
                      Institution of Surveyors of Kenya
                    </a>

                    <a
                      href="https://www.allsk.co.ke/"
                      target="_blank"
                      rel="noreferrer"
                      className="text-gray-400 text-decoration-none hover:text-white"
                    >
                      Association of Licensed Land Surveyors of Kenya (ALLSK)
                    </a>

                    <a
                      href="https://kism.ac.ke/"
                      target="_blank"
                      rel="noreferrer"
                      className="text-gray-400 text-decoration-none hover:text-white"
                    >
                      Kenya Institute of Surveying & Mapping
                    </a>

                    <a
                      href="https://accounts.ecitizen.go.ke/en"
                      target="_blank"
                      rel="noreferrer"
                      className="text-gray-400 text-decoration-none hover:text-white"
                    >
                      E-CITIZEN
                    </a>

                    <a
                      href="https://landcommission.go.ke/"
                      target="_blank"
                      rel="noreferrer"
                      className="text-gray-400 text-decoration-none hover:text-white"
                    >
                      National Land Commission
                    </a>
                  </div>
                </div>
              </div>

              <div className="flex flex-col">
                <div className="footer-widget">
                  <div className="footer-widget-heading">
                    <h3 className="text-white fw-bold underline underline-offset-4 uppercase">Subscribe</h3>
                  </div>

                  <div className="footer-text mb-25">
                    <p className="text-gray-300">
                      Don&apos;t miss to subscribe to our new feeds.
                    </p>
                  </div>

                  <div className="">
                    <form action="/" className="flex">
                      <input
                        type="text"
                        placeholder="Email Address"
                        className="p-2"
                      />
                      <button className="rounded-r-lg bg-blue-600 px-4">
                        <i class="fa-solid fa-envelope text-white"></i>
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>

      {/* footer navigation */}
      <div className="flex flex-col justify-center items-center p-4">
        <div className="flex flex-wrap justify-center gap-3">
          <p><a href="/" className="text-white text-decoration-none">Home</a></p>
          <p><a href="/about" className="text-white text-decoration-none">About</a></p>
          <p><a href="/register" className="text-white text-decoration-none">Register</a></p>
          <p><a href="/resources" className="text-white text-decoration-none">Resources</a></p>
          <p><a href="/contact" className="text-white text-decoration-none">Contact</a></p>
          <p><a href="/privacy" className="text-white text-decoration-none">Privacy</a></p>
        </div>

        <div className="border-t pt-2">
          <p className="text-white">
            Copyright &copy; <CurrentYear />{" "}
            <a href="/" className="text-decoration-none text-white fw-bold">
              SoK Devs
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
