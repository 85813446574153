import React from 'react';

function MemberCard({ position, name, role, profilePic }) {
    return (
        <div className="flex flex-col justify-center items-center gap-1" id="member_Card">
            <img
                alt={position}
                src={profilePic}
                className="rounded-full"
                style={{ maxWidth: "100%", height: "auto", maxHeight: "180px" }}
            />

            <div className="text-center leading-5 mt-2">
                <span className="text-uppercase" style={{ fontSize: 'calc(.5em + .2vw)' }}>{position}</span> <br/>
                <span className="font-medium text-gray-900" style={{ fontSize: 'calc(.6em + .2vw)' }}>{name}</span> <br/>
                <span className="text-gray-700 font-bold uppercase" style={{ fontSize: 'calc(.5em + .2vw)' }}>{role}</span>
            </div>
        </div>
    )
}

export default MemberCard;
