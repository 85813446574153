import React from 'react';
import "./Resources/resources.css";

function BlogCard({ resourceCategory, startYear, endYear, coverageContent, picBackground }) {
    return (
        <article className="relative overflow-hidden rounded-lg card-container">
            <img
                alt="Office"
                src={picBackground}
                className="absolute inset-0 w-full h-full object-cover card-image"
            />

            <div className="relative bg-gradient-to-t from-gray-900/50 to-gray-900/25 pt-32 sm:pt-48 lg:pt-64 card-content">
                <div className="p-4">
                    <h4 className="block text-white"><strong>{startYear} - {endYear}</strong></h4>

                    <h2 className="text-white uppercase font-bold">{resourceCategory}</h2>

                    <p className="text-white/95 font-light">
                        {coverageContent}
                    </p>
                </div>
            </div>
        </article>
    )
}

export default BlogCard;