import React, { useState } from 'react';
import InquiryPic from "./inquiries.jpg";

function Inquiries() {
    const [uploadedDocuments, setUploadedDocuments] = useState([]);
    const [uploadError, setUploadError] = useState("");

    // Function to handle file selection
    const handleFileSelect = (e) => {
        const files = e.target.files;
        const allowedTypes = ['application/pdf', 'image/jpeg', 'image/png', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
        const selectedFiles = Array.from(files);
        const invalidFiles = selectedFiles.filter(file => !allowedTypes.includes(file.type));

        if (invalidFiles.length > 0) {
            setUploadError('Invalid file type. Please upload a DOCX, PNG, JPG, or PDF file.');
            e.target.value = ''; // Clear the file input
        } else {
            setUploadedDocuments([...uploadedDocuments, ...selectedFiles]);
            setUploadError('');
        }
    };

    // Function to remove uploaded file
    const removeFile = (index) => {
        const updatedFiles = [...uploadedDocuments];
        updatedFiles.splice(index, 1);
        setUploadedDocuments(updatedFiles);
    };

    // Function to handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();

        // Perform custom validation
        const form = e.target;
        const requiredFields = form.querySelectorAll('[required]');
        let isValid = true;

        requiredFields.forEach(field => {
            if (!field.value) {
                isValid = false;
                field.classList.add('border-red-500');
            } else {
                field.classList.remove('border-red-500');
            }
        });

        if (isValid) {
            form.submit();
        } else {
            console.log('Please fill in all required fields.');
        }
    };

    return (
        <div className="">
            {/* Hero Image */}
            <div className="hero">
                <img
                    className="w-full h-48 md:h-64 lg:h-96 object-cover object-center"
                    src={InquiryPic}
                    alt="Nature Scene"
                />
            </div>

            <h1 className='py-4 text-center'>Submit your Inquiry</h1>

            {/* Form Container */}
            {/* <div className="container mx-auto mt-8 px-4 lg:px-8">
                <form onSubmit={handleSubmit} className="">
                    <div className="text-center mb-6">
                        <h1 className="text-2xl md:text-3xl font-bold text-gray-800 uppercase">
                            Inquiry Form
                        </h1>
                        <p className="text-gray-600 mt-2">Submit your inquiry and we will get in touch shortly</p>
                    </div>


                    <div className="mb-4">
                        <p className="font-medium text-gray-700 underline">Personal Details</p>

                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-3">
                            <input
                                type="text"
                                className="w-full p-2 border border-gray-300 focus:outline-none focus:border-blue-500 shadow-md"
                                placeholder="First Name"
                                style={{ fontSize: 'calc(.5em + .3vw)' }}
                                required
                            />

                            <input
                                type="text"
                                className="w-full p-2 border border-gray-300 focus:outline-none focus:border-blue-500 shadow-md"
                                placeholder="Last Name"
                                style={{ fontSize: 'calc(.5em + .3vw)' }}
                                required
                            />

                            <input
                                type="text"
                                className="w-full p-2 border border-gray-300 focus:outline-none focus:border-blue-500 shadow-md"
                                placeholder="Phone Number"
                                style={{ fontSize: 'calc(.5em + .3vw)' }}
                            />

                            <input
                                type="email"
                                className="w-full p-2 border border-gray-300 focus:outline-none focus:border-blue-500 shadow-md"
                                placeholder="Email Address"
                                style={{ fontSize: 'calc(.5em + .3vw)' }}
                                required
                            />
                        </div>

                        <div className="py-4">
                            <p className="font-medium text-gray-700 underline">Your Message</p>

                            <textarea
                                className="w-full p-2 border border-gray-300 focus:outline-none focus:border-blue-500 shadow-md"
                                placeholder="Inquiry Message"
                                rows="4"
                                style={{ fontSize: 'calc(.5em + .3vw)' }}
                                required
                            />
                        </div>
                    </div>

                    <div className="mb-6">
                        <p className="block font-bold text-red-800 uppercase mb-2">SUPPORTING EVIDENCE *</p>

                        <div className="flex items-center justify-center w-full">
                            <p
                                htmlFor="dropzone-file"
                                className="flex flex-col items-center justify-center w-full h-48 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100"
                            >
                                <div className="flex flex-col items-center justify-center px-4">
                                    <i class="fa-solid fa-cloud-arrow-up fa-2x mb-2 text-gray-500"></i>
                                    <small className="text-gray-500">
                                        <span className="font-semibold">Click to upload</span> or drag and drop
                                    </small>

                                    <small className="text-gray-500">DOCX, PNG, JPG, or PDF</small>

                                    <small className="text-gray-500 text-center w-3/5">Ensure any image uploaded is clear and indicates your complaint & maximum of 5 Files</small>
                                </div>

                                <input
                                    id="dropzone-file"
                                    type="file"
                                    className="hidden"
                                    accept=".docx,.png,.jpg,.pdf"
                                    onChange={handleFileSelect}
                                    multiple
                                />
                            </p>
                        </div>

                        {uploadError && (
                            <p className="text-red-500 text-sm mt-2">{uploadError}</p>
                        )}

                        {uploadedDocuments.length > 0 && (
                            <div className="mt-4">
                                <p className="text-sm font-bold text-gray-700 underline">Uploaded Documents:</p>
                                <ul className="mt-2">
                                    {uploadedDocuments.map((file, index) => (
                                        <li key={index} className="flex items-center justify-between p-2 border-b border-gray-200">
                                            <span className="text-gray-700">{file.name}</span>
                                            <button
                                                onClick={() => removeFile(index)}
                                                className="text-red-600 font-bold hover:text-red-800"
                                            >
                                                Remove
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>

                    <button className="button mt-4">
                        <span className="button_lg">
                            <span className="button_sl"></span>
                            <span className="button_text">submit</span>
                        </span>
                    </button>
                </form>
            </div> */}
        </div>
    );
}

export default Inquiries;